import React from "react"
import { Table } from "./Table"
import { TableStyles } from "../style/globalStyles"

export const DoctorTable = ({ doctors }) => {
  const columns = [
    {
      Header: "Doctor ID",
      accessor: "doctorId",
    },
    {
      Header: "Name",
      accessor: (doctor) => `${doctor.firstName} ${doctor.lastName}`,
    },

    {
      Header: "M C Reg.",
      accessor: "imcRegistrationNo",
    },

    {
      Header: "Speciality",
      accessor: "speciality",
    },
    {
      Header: "Phone No.",
      accessor: "mobileNo",
    },
    {
      Header: "E-mail",
      accessor: "email",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Active",
      accessor: (isActive) => {
        return isActive.isActive ? "Active" : "Not Active"
      },
    },
    {
      Header: "Verified",
      accessor: (isVerified) => {
        return isVerified.isVerified ? "Verified" : "Not Verified"
      },
    },
    {
      Header: "Subscription",
      accessor: (isSubscription) => {
        return isSubscription.isSubscription ? "Subscribed" : "No Subscription"
      },
    },
  ]

  var data = doctors

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e) => console.log("row clicked", rowInfo),
        }
      }}
      // Pass the page url here to navigate to onClick
      path={"/doctors/doctor"}
      columns={columns}
      data={doctors}
    />
  )

  return <TableStyles>{doctors ? tableRender : " "}</TableStyles>
}
