import React, { useEffect } from "react"
import { connect } from "react-redux"
import { fetchDoctors } from "../../state"
import { Layout } from "../../components/Layout"
import { DoctorsSection } from "../../style/doctors"
import { DoctorTable } from "../../components/DoctorTable"
import { BallBeat } from "react-pure-loaders"

function DoctorsContainer({ doctorsData, fetchDoctors, user = {} }) {
  useEffect(() => {
    // console.log("fetchDoctors being called")
    if (doctorsData.doctors.length === 0) {
      fetchDoctors(user)
    }
  }, [fetchDoctors])

  return (
    <Layout>
      <DoctorsSection>
        <h1>Doctors </h1>
        {doctorsData.loading ? (
          <>
            <br />
            <br />
            <h4>
              Loading Doctors{" "}
              <span>
                <BallBeat color={"#123abc"} loading={doctorsData.loading} />
              </span>{" "}
            </h4>
          </>
        ) : doctorsData.error ? (
          <>
            <br />
            <h4>ERROR ⚠️ FETCHING DOCTORS</h4>
            <br />
            <p style={{ color: "grey" }}>{doctorsData.error}</p>
            <br />
            <br />
          </>
        ) : (
          <>
            {/* <a
              style={{ textDecoration: "underline" }}
              href="https://www.mciindia.org/CMS/information-desk/indian-medical-register"
            >
              MCI India.org
            </a> */}

            {doctorsData.doctors.length === 0 ? (
              <>
                <br />
                <br />
                <h4>No Doctors found</h4>
              </>
            ) : (
              <DoctorTable doctors={doctorsData.doctors} user={user} />
            )}
          </>
        )}
      </DoctorsSection>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    doctorsData: state.doctors,
    user: state.login.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDoctors: (user) => dispatch(fetchDoctors(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsContainer)
